<template>
  <div :style="isCollapse ? 'width:60px' : ''" class="sideBar">
    <div class="logo">
      <h3 class="title">
        <i
          :style="{
            'background-image': 'url(' + activeSystem.icon + ')',
          }"
        ></i>
        <span v-if="!isCollapse">{{ activeSystem.name }}</span>
      </h3>
    </div>
    <el-menu
      :default-active="activeIndex"
      :collapse="isCollapse"
      :collapse-transition="false"
      :unique-opened="true"
      background-color="#242733"
      text-color="#82918F"
      active-text-color="#fff"
      class="el-menu-vertical-demo"
      @select="handleSelect"
    >
      <template v-for="(item, index) in menuList">
        <el-submenu
          v-if="item.subMenuTrees && item.subMenuTrees[0].type == 1"
          :key="index"
          :index="item.url"
        >
          <template slot="title">
            <i :class="item.icon" class="iconfont" />
            <span slot="title">{{ item.menuName }}</span>
          </template>
          <el-menu-item
            v-for="(sub, line) in item.subMenuTrees"
            :key="line"
            :index="path + '/' + sub.url"
            >{{ sub.menuName }}</el-menu-item
          >
        </el-submenu>
        <el-menu-item
          v-else-if="item.type == 1"
          :key="index"
          :index="path + '/' + item.url"
        >
          <i :class="item.icon" class="iconfont" />
          <span slot="title">{{ item.menuName }}</span>
        </el-menu-item>
      </template>
    </el-menu>
  </div>
</template>

<script>
import config from "@/api/static/host.js";
export default {
  data() {
    return {
      isCollapse: false,
      menuList: [],
      path: "",
      activeIndex: "projectBus/dashboard",
      systemList: {
        projectTravel: {
          name: this.$configs.projectText,
          icon: require(`@/assets/images/home/${this.$configs.logoImg}`),
          url: "gps",
        },
      },
    };
  },
  computed: {
    activeSystem() {
      this.handleAuthority();
      this.path = this.$route.path.split("/")[1].split("/")[0];
      return this.path
        ? this.systemList[this.path]
        : this.systemList.projectTravel;
    },
  },
  created() {
    //事件订阅
    this.$bus.$on("isCollapse", (res) => {
      this.isCollapse = res;
    });
    this.activeIndex = window.sessionStorage.getItem("routeName");
    if (JSON.parse(sessionStorage.getItem("menuList")).subMenuTrees)
      this.menuList = JSON.parse(
        sessionStorage.getItem("menuList")
      ).subMenuTrees;
  },
  watch: {
    $route: function (val) {
      this.activeIndex = val.path.substr(1);
    },
  },
  methods: {
    changeCollapse() {
      this.isCollapse = !this.isCollapse;
    },
    handleAuthority() {
      // 处理按钮菜单权限
      if (this.menuList && this.menuList.length > 0)
        this.menuList.forEach((element) => {
          // 一级菜单
          if (this.path + "/" + element.url === this.activeIndex) {
            var btnMenu = [];
            if (element.subMenuTrees) {
              element.subMenuTrees.forEach((item) => {
                btnMenu.push(item.menuName);
              });
            } else {
              btnMenu.push("###");
            }
            this.$store.dispatch("now_Menus", btnMenu.join());
          } else {
            // 二级菜单
            if (element.subMenuTrees) {
              element.subMenuTrees.forEach((sub) => {
                if (this.path + "/" + sub.url === this.activeIndex) {
                  var btnMenu = [];
                  if (sub.subMenuTrees) {
                    sub.subMenuTrees.forEach((item) => {
                      btnMenu.push(item.menuName);
                    });
                  } else {
                    btnMenu.push("###");
                  }
                  this.$store.dispatch("now_Menus", btnMenu.join());
                }
              });
            }
          }
        });
    },
    handleSelect(key, keyPath) {
      if (key == "projectTravel/smartManager") {
        let token = sessionStorage.getItem("token");
        let baseUrl = config.baseUrl;
        let url = baseUrl + `smartMarketing/manage/#/?token=${token}`;
        if (!process.env.NODE_ENV.includes("pro")) {
          url = `http://192.168.0.199:8919/#/?token=${token}`;
        }
        window.open(url);
      } else {
        this.$router.push({ path: `/${key}` });
      }
    },
  },
  beforeDestroy() {
    this.$bus.$off("isCollapse");
  },
};
</script>

<style lang="scss" scoped>
.sideBar {
  @include themify() {
    .logo {
      padding-left: 16px;
      margin-bottom: 30px;
      .title {
        color: #fff;
        font-size: 16px;
        display: flex;
        flex-wrap: nowrap;
        justify-content: left;
        align-items: center;
        width: 100%;
        i {
          display: inline-block;
          content: "";
          height: 26px;
          width: 26px;
          background-repeat: no-repeat;
          background-size: contain;
          margin-right: 6px;
          border-radius: 50%;
        }
      }
      img {
        width: 32px;
        height: 32px;
        margin-left: 20px;
        margin-right: 8px;
      }
      .system-name {
        font-size: 16px;
        font-weight: bold;
      }
    }
    .el-menu {
      border: none;
    }
    .el-menu--collapse {
      width: 60px;
    }
    .iconfont {
      font-size: 16px;
    }
    .el-submenu .el-menu-item {
      margin-left: 10px;
    }
    .el-menu-vertical-demo span {
      margin-left: 5px;
      font-size: 14px;
    }
    .el-menu-item,
    .el-submenu__title {
      height: 48px !important;
      line-height: 48px !important;
    }
    .el-menu-item i,
    .el-submenu__title i {
      color: inherit;
    }
    .el-submenu__title:hover {
      color: white !important;
      background-color: themed("b3") !important;
    }
    .el-menu-item:hover {
      color: white !important;
      background-color: themed("b3") !important;
    }
    .el-menu-item.is-active {
      background-color: themed("b4") !important;
    }
    .el-submenu.is-active >>> .el-submenu__title i,
    .el-submenu.is-active >>> .el-submenu__title {
      color: white !important;
    }
  }
}
</style>
