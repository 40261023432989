var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "logs-info" },
    _vm._l(_vm.logsList, function (item, index) {
      return _c(
        "div",
        { key: index, staticClass: "info" },
        [
          _c("div", { staticClass: "title" }, [_vm._v(_vm._s(item.vision))]),
          _c("div", { staticClass: "time" }, [
            _vm._v("更新时间：" + _vm._s(item.updateTime)),
          ]),
          _vm._l(item.details, function (element, i) {
            return _c("div", { key: i, staticClass: "detail" }, [
              _vm._v(" " + _vm._s(i + 1 + ". ") + _vm._s(element) + " "),
            ])
          }),
        ],
        2
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }