<template>
  <div class="header">
    <div class="header-left">
      <i
        :class="isCollapse ? 'its_caidanzhankai' : 'its_caidanguanbi'"
        class="iconfont"
        @click="changeCollapse"
      ></i>
    </div>

    <div class="header-center">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item v-for="(item, index) in breadList" :key="index">{{
          item.meta.title
        }}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="header-serach">
      <!-- <el-input v-model="headerSerach" placeholder="搜索..."></el-input> -->
    </div>
    <!-- 消息通知 -->
    <div
      class="message-box"
      @mouseenter="messageBoxFun"
      @mouseleave="messageOutFun"
    >
      <div class="message-number">{{ messageNUMBER }}</div>
      <i class="iconfont its_tongzhi"></i>
      <div v-show="messageShow" class="message-content">
        <!-- 头部操作栏 -->
        <div class="content-top">
          <div :class="contentClass[0]" @click="contentClassFun(0)">
            未读({{ messageNUMBER }})
          </div>
          <div :class="contentClass[1]" @click="contentClassFun(1)">通知</div>
        </div>
        <!-- 内容部分 -->
        <div ref="content" class="content" @scroll="isScrollBottom">
          <div
            v-for="(item, index) in messageData"
            :key="index"
            class="content-li"
            @click="contentLiFun(item)"
          >
            <div class="left">
              <div class="img"></div>
              <span v-show="item.isAdminRead == 0"></span>
            </div>
            <div class="right">
              <div class="top">
                定制客运乘客
                <span>{{ item.orderPhone }}</span
                >已下单，请及时安排调度
              </div>
              <div class="bottom">{{ item.createTime }}</div>
            </div>
          </div>
        </div>
        <div class="content-footer" @click="allReadFun">标记全部已读</div>
      </div>
    </div>
    <div class="header-right">
      <div class="header-user-con">
        <!-- 用户名下拉菜单 -->
        <el-dropdown>
          <!-- 用户头像 -->
          <p class="user-avator">
            <img
              alt
              srcset
              src="../../assets/images/home/header.png"
              class="header_Img"
            />
            <span class="user-name">
              <span>{{ username }}</span>
              <i class="el-icon-more el-icon--right"></i>
            </span>
          </p>
          <el-dropdown-menu slot="dropdown" trigger="click">
            <el-dropdown-item @click.native="toUpdateLogs">
              <i class="iconfont its_ba_shuaxin"></i>
              更新日志
            </el-dropdown-item>
            <el-dropdown-item @click.native="toUpdate">
              <i class="iconfont its_xiugaimima"></i>
              修改密码
            </el-dropdown-item>
            <!-- <el-dropdown-item @click.native="toHead">
              <i class="iconfont its_xiugaitouxiang"></i>
              修改头像
            </el-dropdown-item>-->
            <el-dropdown-item @click.native="logOut">
              <i class="iconfont its_tuichu"></i>
              退出登录
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>

    <!-- 修改头像 -->
    <el-dialog title="修改头像" :visible.sync="dialogHeadVisible" width="30%">
      <div class="body">
        <p>图片上传</p>
        <el-upload
          class="avatar-uploader"
          action="https://jsonplaceholder.typicode.com/posts/"
          :show-file-list="false"
          :on-success="handleAvatarSuccess"
          :on-remove="handleRemove"
          :before-upload="beforeAvatarUpload"
        >
          <img v-if="imageUrl" :src="imageUrl" class="avatar" />
          <span
            v-if="imageUrl"
            class="el-upload-action"
            @click.stop="handleRemove()"
          >
            <i class="el-icon-delete"></i>
          </span>
          <i v-else stop class="el-icon-upload2 avatar-uploader-icon"></i>
        </el-upload>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          size="small"
          type="default"
          @click="dialogHeadVisible = false"
          >取 消</el-button
        >
        <el-button size="small" type="primary" @click="confirmHead"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <!-- 修改密码 -->
    <el-dialog title="修改密码" :visible.sync="dialogVisible" width="30%">
      <div class="body">
        <el-form
          ref="ruleForm"
          :model="ruleForm"
          :rules="rules"
          label-width="90px"
          label-position="left"
          class="demo-form dialog-form"
        >
          <el-form-item label="旧密码" prop="oldPassword">
            <el-input
              v-model.trim="ruleForm.oldPassword"
              type="password"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item label="新密码" prop="password">
            <el-input
              v-model.trim="ruleForm.password"
              type="password"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item label="确认密码" prop="checkPass">
            <el-input
              v-model.trim="ruleForm.checkPass"
              type="password"
              autocomplete="off"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" type="default" @click="dialogVisible = false"
          >取 消</el-button
        >
        <el-button
          size="small"
          type="primary"
          @click="confirmUpdatePsw('ruleForm')"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <!-- 更新日志 -->
    <el-dialog title="更新日志" :visible.sync="logsDdialogVisible" width="860px">
      <update-logs></update-logs>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" type="default" @click="logsDdialogVisible = false"
          >取 消</el-button
        >
        <!-- <el-button
          size="small"
          type="primary"
          @click="confirmUpdatePsw('ruleForm')"
          >确 定</el-button
        > -->
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  loginOut,
  updatePassword,
  notReadCountAPI,
  orderPhonesAPI,
  adminReadStatusAPI,
  adminReadStatusUpdateAPI,
} from "@/api/lib/api.js";
import { checkPassword } from "@/common/utils/index.js";
import md5 from "js-md5";
import Bus from "@/common/utils/bus.js";
import updateLogs from "./updateLogs.vue"
export default {
  components: { updateLogs },
  data () {
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.ruleForm.password) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    var validatePassRepeat = (rule, value, callback) => {
      if (value === this.ruleForm.oldPassword) {
        callback(new Error("新密码不能等于旧密码!"));
      } else {
        callback();
      }
    };
    return {
      contentClass: ["btn-1 contentAction", "btn-1"],
      total: 0,
      breadList: [],
      nowId: null,
      activeName: "first",
      isEdit: false,
      dialogVisible: false,
      dialogHeadVisible: false,
      username: "",
      headerSerach: "",
      imageUrl: "",
      tableData: [],
      textForm: {
        name: null,
        detail: null,
      },
      ruleForm: {
        oldPassword: "",
        password: "",
        checkPass: "",
        userId: "",
      },
      isCollapse: false,
      rules: {
        password: [
          { required: true, message: "请输入密码", trigger: "blur" },
          { validator: checkPassword, trigger: "blur" },
          { validator: validatePassRepeat, trigger: "blur" },
        ],
        oldPassword: [
          { required: true, message: "请输入旧密码", trigger: "change" },
        ],
        checkPass: [
          { required: true, message: "请输入确认密码", trigger: "blur" },
          { validator: validatePass2, trigger: "blur" },
        ],
      },
      messageShow: false,
      messageParam: {
        isAdminRead: 0,
        companyId: sessionStorage.getItem("companyId"),
        pageSize: 6,
        currentPage: 1,
      },
      messageData: [],
      total: 0,
      pageNum: 1,
      messageTime: "",
      messageNUMBER: 0, //  未读消息条
      logsDdialogVisible: false,//更新日志
    };
  },
  mounted () {
    let that = this;
    Bus.$on("clearMessageInterval", () => {
      clearInterval(this.messageTime);
    });
    this.messageRender(1);
  },

  watch: {
    $route () {
      this.getBreadcrumb();
    },
    "isRefreshLive.refreshDownLoad" (newvalue) {
      if (newvalue === true) {
        this.getDownloadLength();
      }
    },
    "$store.state.user.messageNumber" (val) {
      this.messageNUMBER = val;
    },
  },
  methods: {
    isScrollBottom () {
      // 是否滚动到了底部
      this.box = this.$refs.content;
      var clientHeight = this.box.clientHeight;
      var scrollTop = this.box.scrollTop;
      var scrollHeight = this.box.scrollHeight;
      if (scrollTop + clientHeight == scrollHeight) {
        this.pageNum++;
        this.messageRender(2);
      }
    },
    // 点击全部已读
    allReadFun () {
      let idArray = [];
      this.messageData.map((item) => {
        idArray.push(item.id);
      });
      adminReadStatusAPI({ orderMessageIds: idArray.join(",") }).then((res) => {
        if (res.code == "SUCCESS") {
          this.pageNum = 6;
          this.messageRender(1);
        }
      });
    },
    // 点击某一条的时候跳转到调度页面
    contentLiFun (item) {
      adminReadStatusUpdateAPI(item.id).then((res) => {
        if (res.code == "SUCCESS") {
          this.clearData();
          this.messageRender(1);
        } else {
          this.$message.error(res.msg);
        }
      });
      this.$store.commit("PAIDORDERID", item.paidOrderId);
      this.$router.push({
        path: "/projectTravel/dispatchManagement",
        query: {
          num: this.RndNum(4),
        },
      });
    },
    // 消息中心数据获取
    messageRender (num) {
      if (num == 2) {
        this.messageParam.pageSize = this.pageNum * 6;
      }
      orderPhonesAPI(this.messageParam).then((res) => {
        if (res.code == "SUCCESS") {
          this.messageData = res.data.list;
          this.total = res.data.total;
        } else {
          this.$message.success(res.msg);
        }
      });
      // 未读消息条数
      notReadCountAPI().then((ref) => {
        if (ref.code == "SUCCESS") {
          this.messageNUMBER = ref.data;
        }
      });
    },
    // 初始化方法
    clearData () {
      this.pageNum = 1;
      this.messageParam.currentPage = 1;
      this.messageParam.pageSize = 6;
    },
    contentClassFun (num) {
      switch (num) {
        case 0:
          this.contentClass = ["btn-1 contentAction", "btn-1"];
          this.messageParam.isAdminRead = 0;
          this.clearData();
          this.messageRender(1);
          break;
        case 1:
          this.contentClass = ["btn-1", "btn-1 contentAction"];
          this.messageParam.isAdminRead = "";
          this.clearData();
          this.messageRender(1);
          break;
      }
    },
    // 消息中心鼠标移出事件
    messageOutFun () {
      this.messageShow = false;
    },
    // 消息中心鼠标移入事件
    messageBoxFun () {
      this.messageRender(1);
      this.messageShow = true;
    },
    logOut () {
      this.$confirm("确认退出登录？", "提示")
        .then((_) => {
          this.confirmLogout();
          localStorage.removeItem("vuex");
          this.$store.dispatch("clear_all");
          this.$store.dispatch("clear_MenuCtr");
          this.$store.dispatch("clear_user");
          this.$store.dispatch("clear_Menus");
          this.$router.push({ path: "/login" });
        })
        .catch((_) => { });
    },
    confirmLogout () {
      loginOut({ token: sessionStorage.getItem("token") })
        .then((res) => {
          if (res.code === "SUCCESS") {
            clearInterval(this.messageTime);
            localStorage.removeItem("vuex");
            this.$store.dispatch("clear_all");
            this.$store.dispatch("clear_MenuCtr");
            this.$store.dispatch("clear_user");
            this.$store.dispatch("clear_Menus");
            sessionStorage.clear();
            this.$router.push({ path: "/login" });
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    //发布日志
    toUpdateLogs () {
      this.logsDdialogVisible = true;
    },
    //更新密码
    toUpdate () {
      this.dialogVisible = true;
      if (this.$refs.ruleForm !== undefined) {
        this.$refs.ruleForm.resetFields();
      }
    },
    handleEdit (row) {
      this.nowId = row.id;
      this.isEdit = true;
    },
    toHead () {
      this.dialogHeadVisible = true;
    },

    confirmHead () { },
    confirmUpdatePsw (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.updatedPwd();
        } else {
          return false;
        }
      });
    },

    updatedPwd () {
      const data = {
        password: md5(this.ruleForm.oldPassword),
        newPassword: md5(this.ruleForm.password),
        checkPassword: md5(this.ruleForm.checkPass),
      };
      updatePassword(data).then((res) => {
        if (res.code === "SUCCESS") {
          this.$message.success("修改成功");
          // 退出登录
          localStorage.removeItem("vuex");
          this.$store.commit("clear_all");
          this.$store.commit("clear_MenuCtr");
          this.$store.commit("clear_user");
          this.$store.commit("clear_Menus");
          sessionStorage.removeItem("token");
          sessionStorage.removeItem("username");
          sessionStorage.removeItem("menuList");

          this.$router.push({ path: "/login" });
          this.$yhsdp.CloseSocketLink();
        } else {
          this.$message.error("修改失败");
        }
      });
    },
    // 移除图片
    handleRemove () {
      this.imageUrl = "";
    },
    // 上传成功回调
    handleAvatarSuccess (res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
    },
    // 上传前格式和图片大小限制
    beforeAvatarUpload (file) {
      const type =
        file.type === "image/jpeg" ||
        "image/jpg" ||
        "image/webp" ||
        "image/png";
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!type) {
        this.$message.error("图片格式不正确!(只能包含jpg，png，webp，JPEG)");
      }
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 2MB!");
      }
      return type && isLt2M;
    },
    onDarkTheme () {
      this.$addStorageEvent(1, "YH_BF_THEME", "theme-dark");
    },
    onLightTheme () {
      this.$addStorageEvent(1, "YH_BF_THEME", "theme-light");
    },
    isHome (route) {
      return route.path.indexOf("dashboard") > -1;
    },
    getBreadcrumb () {
      this.breadList =
        this.$route.matched.filter((item) => item.meta.title) || [];
    },
    changeCollapse () {
      this.isCollapse = !this.isCollapse;
      this.$bus.$emit("isCollapse", this.isCollapse);
    },
  },
  created () {
    this.username = sessionStorage.getItem("realname");
    this.getBreadcrumb();
  },
};
</script>

<style lang="scss" scoped>
.message-box {
  width: 60px;
  height: 100%;
  position: relative;
  .message-number {
    padding: 2px;
    min-width: 12px;
    min-height: 12px;
    background: red;
    border-radius: 50%;
    color: #ffffff;
    text-align: center;
    font-size: 12px;
    position: absolute;
    z-index: 33;
    top: 16px;
    left: 30px;
  }
  i {
    cursor: pointer;
    font-size: 16px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .message-content {
    width: 280px;
    height: 392px;
    position: absolute;
    border-radius: 4px;
    box-shadow: 0px 2px 6px 0px rgba(52, 52, 52, 0.3);
    right: -75px;
    z-index: 666;
    bottom: -392px;
    background: #ffffff;
    .content {
      width: 100%;
      height: calc(100% - 42px - 32px - 14px);
      overflow-y: auto;
      padding: 7px 0;
      .content-li:hover {
        transition: all 0.1s;
        background: #f8fafe;
      }
      .content-li {
        cursor: pointer;
        width: calc(100% - 36px);
        height: calc(79px - 24px);
        border-bottom: 1px solid #e2e2e2;
        padding: 12px 18px;
        display: flex;
        .left {
          width: 35px;
          height: 100%;
          position: relative;
          span {
            position: absolute;
            border-radius: 50%;
            background: red;
            width: 8px;
            height: 8px;
            top: 4px;
            left: 22px;
          }
          .img {
            background: url('../../assets/images/home/order_icon.png') no-repeat;
            background-size: 100%;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            margin-top: 4px;
          }
        }
        .right {
          width: calc(100% - 35px);
          height: 100%;
          span {
            color: #336ffe;
          }
          .top {
            font-size: 12px;
            width: 100%;
            height: calc(100 - 22px);
            color: #333;
            line-height: 18px;
          }
          .bottom {
            line-height: 22px;
            height: 22px;
            font-size: 12px;
            color: #8c8c8c;
          }
        }
      }
    }
    .content-top {
      height: 42px;
      font-size: 14px;
      color: #333333;
      border-bottom: 1px solid #e2e2e2;
    }
    .btn-1 {
      line-height: 40px;
      height: 40px;
      width: 90px;
      text-align: center;
      float: left;
      cursor: pointer;
    }
    .contentAction {
      color: #336ffe;
      border-bottom: 2px solid #336ffe !important;
    }
    .content-footer {
      position: absolute;
      box-shadow: 0px -2px 2px 0px rgba(0, 0, 0, 0.1);
      border-radius: 0px 0px 4px 4px;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 28px;
      line-height: 28px;
      cursor: pointer;
      text-align: center;
      font-size: 12px;
    }
  }
}
.header {
  box-sizing: border-box;
  width: 100%;
  height: 60px;
  font-size: 22px;
  display: flex;
  align-items: center;
}
.header-left {
  flex: 1;
  .iconfont {
    font-size: 22px;
  }
}
.header-center {
  flex: 12;
}
.header-serach {
  flex: 2;
}
.collapse-btn {
  flex: 2;
  padding: 0 21px;
  cursor: pointer;
  line-height: 60px;
}
.header-right {
  // flex: 1;
  display: flex;
  height: 3.5vh;
  align-items: center;
  justify-content: center;
  .header-user-con {
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  >>> .el-dropdown {
    color: #fff;
  }
  .user-avator {
    cursor: pointer;
    font-size: 16px;
    color: #606266;
    text-align: right;
    display: flex;
    align-content: center;
    align-items: center;
    & > img {
      height: 3.5vh;
      width: 3.5vh;
      border-radius: 50%;
    }
    & > .user-name {
      height: 3.5vh;
      display: flex;
      align-content: center;
      align-items: center;
      & > span {
        height: 3.5vh;
        line-height: 3.5vh;
        max-width: 5vw;
        overflow: hidden;
        display: inline-block;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
  .el-icon-more {
    margin-left: 20px;
  }
}
.el-dropdown-link {
  color: #fff;
  cursor: pointer;
}
/deep/.demo-dialog-form .el-form-item__content {
  line-height: 32px;
  height: 32px;
}
.avatar-uploader {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  background: url('../../assets/images/home/header.png') no-repeat;
  background-size: 100% 100%;
}
.avatar-uploader-icon {
  font-size: 0;
  color: #fff;
  width: 120px;
  height: 120px;
  line-height: 120px;
  text-align: center;
}
.avatar-uploader-icon:hover {
  font-size: 28px;
  background-color: rgba(0, 0, 0, 0.3);
}
.avatar {
  position: relative;
  width: 120px;
  height: 120px;
  display: block;
}
.el-upload-action {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  font-size: 0;
  color: #fff;
  text-align: center;
  line-height: 120px;
}
.el-upload-action:hover {
  font-size: 20px;
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.3);
}
</style>
