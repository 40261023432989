var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-container",
    { staticClass: "homePage" },
    [
      _c(
        "el-aside",
        {
          staticStyle: { background: "#242733" },
          attrs: { width: _vm.isCollapse ? "60px" : "240px" },
        },
        [_c("side-bar")],
        1
      ),
      _c(
        "el-container",
        [
          _c("el-header", { staticClass: "el-header" }, [_c("my-header")], 1),
          _c("el-main", { attrs: { id: "el-main" } }, [_c("router-view")], 1),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }