var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "sideBar", style: _vm.isCollapse ? "width:60px" : "" },
    [
      _c("div", { staticClass: "logo" }, [
        _c("h3", { staticClass: "title" }, [
          _c("i", {
            style: {
              "background-image": "url(" + _vm.activeSystem.icon + ")",
            },
          }),
          !_vm.isCollapse
            ? _c("span", [_vm._v(_vm._s(_vm.activeSystem.name))])
            : _vm._e(),
        ]),
      ]),
      _c(
        "el-menu",
        {
          staticClass: "el-menu-vertical-demo",
          attrs: {
            "default-active": _vm.activeIndex,
            collapse: _vm.isCollapse,
            "collapse-transition": false,
            "unique-opened": true,
            "background-color": "#242733",
            "text-color": "#82918F",
            "active-text-color": "#fff",
          },
          on: { select: _vm.handleSelect },
        },
        [
          _vm._l(_vm.menuList, function (item, index) {
            return [
              item.subMenuTrees && item.subMenuTrees[0].type == 1
                ? _c(
                    "el-submenu",
                    { key: index, attrs: { index: item.url } },
                    [
                      _c("template", { slot: "title" }, [
                        _c("i", { staticClass: "iconfont", class: item.icon }),
                        _c(
                          "span",
                          { attrs: { slot: "title" }, slot: "title" },
                          [_vm._v(_vm._s(item.menuName))]
                        ),
                      ]),
                      _vm._l(item.subMenuTrees, function (sub, line) {
                        return _c(
                          "el-menu-item",
                          {
                            key: line,
                            attrs: { index: _vm.path + "/" + sub.url },
                          },
                          [_vm._v(_vm._s(sub.menuName))]
                        )
                      }),
                    ],
                    2
                  )
                : item.type == 1
                ? _c(
                    "el-menu-item",
                    { key: index, attrs: { index: _vm.path + "/" + item.url } },
                    [
                      _c("i", { staticClass: "iconfont", class: item.icon }),
                      _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                        _vm._v(_vm._s(item.menuName)),
                      ]),
                    ]
                  )
                : _vm._e(),
            ]
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }