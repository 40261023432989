<template>
  <div class="logs-info">
    <div class="info" v-for="(item, index) in logsList" :key="index">
      <div class="title">{{ item.vision }}</div>
      <div class="time">更新时间：{{ item.updateTime }}</div>
      <div class="detail" v-for="(element, i) in item.details" :key="i">
        {{ i + 1 + ". " }}{{ element }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      logsList: [
      {
          vision: "v9.0.7",
          updateTime: "2025年03月04日",
          details: [
            "线路的下车点，新增拖拽排序功能，乘客端按顺序展示下车点",
            "设置上、下车点，支持同时创建固定点、区域",
            "调度派车提示信息优化，更符合业务",
            "调度移动端功能增强",
          ],
        },
      {
          vision: "v9.0.5",
          updateTime: "2025年01月15日",
          details: [
            "定制客运业务：线路委托售票授权配置、外部委托售票（客运联盟委托售票）",
          ],
        },
        {
          vision: "v9.0.4",
          updateTime: "2024年12月25日",
          details: [
            "包车功能升级：升级为定制包车，用户提交包车需求，双方协商价格，完成行程",
          ],
        },
        {
          vision: "v9.0.2",
          updateTime: "2024年12月05日",
          details: [
            "调度新增筛选器，支持筛选待派单班次，派车信息新增司机联系方式",
            "待派单乘客新增购票方式，手动完成行程后客运凭单自动更新",
            "派车新增班次分类，方便快速指派到订单所属班次",
            "生成排班新增开班属性筛选",
            "线路及班次新增标签信息和提示信息",
            "订票新增补票派车功能",
            "乘客端行程新增车辆位置查看",
            "智慧经营用户数据、大屏数据、运营统计数据修复",
            "汽车票订票明细新增退票时间筛选",
            "汽车票结算公式调整",
          ],
        },
        {
          vision: "v9.0.1",
          updateTime: "2024年9月12日",
          details: [
            "优化菜单结构",
            "新增更新通知",
            "优化订票和预留功能",
            "派车信息优化",
            "新增定制客运和汽车票售罄后推荐",
            "线路新增独立的规则配置",
            "优化线路绑定交互操作",
            "账户数据权限升级",
            "包车、租车拆分为独立功能",
            "新增乘客注册来源统计",
            "合并调度移动端至司机端",
            "首页菜单UI升级",
            "新增行程单统计",
            "调度任务升级",
            "优化乘客登录常驻问题",
            "允许乘客修改订单的上下车点、乘车手机号",
            "线路支持修改起点站和终点站的名称",
            "驾驶员登录手机号支持修改",
          ],
        },
        {
          vision: "v9.0.0",
          updateTime: "2024年8月16日",
          details: [
            "调度功能升级：支持快捷改班、修改票位、锁票",
            "新增一班多车",
            "排班管理升级，新增流水班",
            "新增售票、派车查询",
            "新增财务管理，包含车辆、线路、司机、公司不同维度的检票凭单，新增手续费、购票未乘车数据统计分析",
            "分账汇总账单和分账明细升级",
            "售后退款优化，限制售后退款时间为2天内",
            "购票页面新增购票须知弹窗",
            "选择上下车点升级，优化了上下车点搜索和选择的方式",
            "半次列表展示方式升级，适应流水班、定班多种班次类型",
            "司机排班方式升级，可自主选择要出发的车次",
            "司机结束班次任务升级，系统自动结束，无需司机手动操作",
            "检票方式优化，每个订单独立检票",
            "司机预留票升级",
            "调度预留、派车、改班功能升级，支持派车，改派，修改班次状态、票位、锁票，支持流水班分配乘客",
          ],
        },
      ],
    };
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
.logs-info {
  height: 61vh;
  overflow-y: auto;
  .info {
    padding: 0 16px;
    margin-bottom: 20px;
    .title {
      color: #282828;
      font-weight: 500;
      font-size: 16px;
    }
    .time {
      margin: 8px 0;
    }
    .detail {
      line-height: 22px;
      text-indent: 1rem;
    }
  }
}
</style>
